/*
 * 업무구분: 고객
 * 화 면 명: MSPCM019M
 * 화면설명: 동의결과처리 확인
 * 접근권한: 일반사용자, 지점장, 지역단스텝 (U000, F110, F102)
 * 작 성 일: 2022.07.05
 * 작 성 자: 이태훈
 */
<template>
  <ur-page-container :show-title="true" title="동의결과처리 확인" type="subpage" @on-header-left-click="fn_Back">
    <ur-box-container direction="column" alignV="start">
      <!-- 동의완료 : 본인 -->
      <ur-box-container v-if="custCardVO.saveMode !== 'CHECK'" alignV="start" componentid="ur_box_container_001" direction="column" class="ns-result-box">
        <div class="info-box">
          <div class="name">
            <span>{{custCardVO.omitCustNm}}</span>
            <mo-badge class="badge-sample-badge" text="본인" shape="status">본인</mo-badge>
          </div>
          <span class="txtBlack">동의완료</span>
        </div>
        <div class="gray-box">
          <p class="info-txt">{{custCardVO.age}}세 {{custCardVO.sxdsCdNm}}</p>
          <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check">
            <mo-checkbox v-if="custCardVO.infoPrcusCnsntTypCd!='19'" class="is-checked" readonly>필수컨설팅 동의완료</mo-checkbox>
            <mo-checkbox v-if="custCardVO.infoPrcusCnsntTypCd!='17'" class="is-checked" readonly>마케팅 동의완료</mo-checkbox>
          </ur-box-container>
        </div>
      </ur-box-container>

      <!-- 동의완료 : 세대원 -->
      <ur-box-container v-for="(item, idx) in this.existHoshInfoListInqrVO" :key="idx" alignV="start" componentid="ur_box_container_001" direction="column" class="ns-result-box">
        <div class="info-box">
          <div class="name">
            <span>{{$bizUtil.omitStr(item.custNm, 10)}}</span>

            <mo-badge v-if="item.mnCustRltnCd === '03'" class="badge-sample-badge" text="자녀" shape="status">자녀</mo-badge>
            <mo-badge v-else-if="item.mnCustRltnCd === '04'" class="badge-sample-badge" text="손자" shape="status">손자</mo-badge>
            <mo-badge v-else class="badge-sample-badge" text="기타" shape="status">기타</mo-badge>
          </div>
          <span class="txtBlack">동의완료</span>
        </div>
        <div class="gray-box">
          <p class="info-txt">{{item.age}}세 {{item.sxdsCdNm}}</p>
          <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check">
            <mo-checkbox v-if="item.infoPrcusCnsntTypCd!='19'" class="is-checked" readonly>필수컨설팅 동의완료</mo-checkbox>
            <mo-checkbox v-if="item.infoPrcusCnsntTypCd!='17'" class="is-checked" readonly>마케팅 동의완료</mo-checkbox>
          </ur-box-container>
        </div>
      </ur-box-container>

      <!-- 후보자등록, 고객추가등록 버튼-->
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-round-btn ns-no-line">
        <mo-button v-if="showRcBtn" class="ns-btn-round" @click="fn_MoveMSPRC150M">후보자 등록</mo-button>
        <mo-button v-else class="ns-btn-round" @click="fn_MoveMSPPI700M">가입보장설계</mo-button>
        <br/>
        <mo-button class="ns-btn-round" @click="fn_MoveMSPCM001M">고객 추가 등록</mo-button>
      </ur-box-container>

      <div class="ns-height60"></div>
      <!-- 완료 버튼-->
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="mo_button_001" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_MovePage">완료</mo-button>
        </div>
      </ur-box-container>

    </ur-box-container>
  </ur-page-container>
</template>

<script>
/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import moment from 'moment'
import DateUtil from '@/ui/sp/common/exDateUtils'

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPCM019M',
  // 현재 화면 ID
  screenId: 'MSPCM019M',

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      lv_UserInfo: {}, // 사용자 정보

      agreeTypeMcnstList: [],
      agreeTypeMktList: [],
      existHoshInfoListInqrVO: [],
      newHoshInfoListInqrVO: [],
      custCardVO: {},
      showRcBtn: false
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  beforeCreate () {
    if (_.isEmpty(this.$route.params)) {
      // router param 없을시, 메인홈으로 전환
      this.$router.push({ name: 'MSPBC002M' })
    }
  },
  created() {
    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_Back)

    //window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_BackBtnClicked) // listen to Event
    this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo
    if (this.$route.params !== null && this.$route.params.custCardVO !== undefined && this.$route.params.name === 'MSPCM012M') {
      this.custCardVO = this.$route.params.custCardVO
      this.fn_bindCompCustData()
    }

    let tmpObj = this.getStore('rcStore').getters.getState.custInfo
    if (!this.$bizUtil.isEmpty(tmpObj.custNm)) {
      this.showRcBtn = true
    }
      
  },
  mounted () {
    // 화면접속로그 등록
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  beforeDestroy () {
    // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_Back)
  },
  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /******************************************************************************
    * @function fn_bindCompCustData
    * @notice   고객등록동의 완료 데이터 바인딩
    ******************************************************************************/
    fn_bindCompCustData () {
      // 주고객 데이터
      // 고객명
      this.custCardVO.omitCustNm = this.$bizUtil.omitStr(this.custCardVO.custNm, 10)

      this.custCardVO.age = DateUtil.getInsuranceAge(this.custCardVO.knb, moment(new Date().toISOString()).format('YYYYMMDD')) // 보험나이
      let gender = this.$bizUtil.getGenderFromKnb(this.custCardVO.knb) // 성별

      if (gender === 'female') {
        this.custCardVO.sxdsCdNm = '여자'
      } else {
        this.custCardVO.sxdsCdNm = '남자'
      }

      // 세대원 데이터
      this.existHoshInfoListInqrVO = this.custCardVO.iCHoshInfoListInqrVO

      for (let i = 0; i < this.existHoshInfoListInqrVO.length; i++) {
        this.existHoshInfoListInqrVO.idx = i + 1
        this.existHoshInfoListInqrVO[i].age = DateUtil.getInsuranceAge(this.existHoshInfoListInqrVO[i].knb, moment(new Date().toISOString()).format('YYYYMMDD')) // 보험나이
        let hoshGender = this.$bizUtil.getGenderFromKnb(this.existHoshInfoListInqrVO[i].knb) // 성별

        if (hoshGender === 'female') {
          this.existHoshInfoListInqrVO[i].sxdsCdNm = '여자'
        } else {
          this.existHoshInfoListInqrVO[i].sxdsCdNm = '남자'
        }
      }
    },
    /******************************************************************************
    * @function fn_MoveMSPCM001M
    * @notice   고객추가등록버튼 클릭 시 호출, MSPCM001M화면으로 이동
    ******************************************************************************/
    fn_MoveMSPCM001M () {
      // 현재 화면을 history 배열에 저장
      this.$commonUtil.setLastScreen(this.$options.screenId)

      let pageCnt = window.vue.getStore('cmStore').getters.getState.viewCntCM050M
      this.$router.go(-pageCnt)
    },
    /******************************************************************************
    * @function fn_MovePage
    * @notice   메인화면/진입화면 으로 이동
    ******************************************************************************/
    fn_MovePage () {
      let preSrnObj = window.vue.getStore('cmStore').getters.getState.preSrnObjCM050M
      window.vue.getStore('cmStore').dispatch('PRE_SRN_OBJ_CM050M', {}) // 진입전 정보 초기화

      // GA 매니저 대행 컨설턴트 변경시
      if(this.$cmUtil.fn_GachangeTF()){
        this.$router.push({ name: 'MSPBC002M' })
      }else{
        if (_.isEmpty(preSrnObj.rtnParam)) {
          let pParmas = {
            chnlCustId: this.custCardVO.chnlCustId, // 채널고객ID
            cnsltNo: this.lv_UserInfo.userId // 컨설턴트 번호
          }
          this.$router.push({ name: 'MSPCM270M', params: pParmas })
        } else {
          this.$router.push({ name: preSrnObj.srnId, params: preSrnObj.rtnParam })
        }
      }

    },
    /******************************************************************************
    * @function fn_MoveMSPRC150M
    * @notice   후보자등록화면으로 이동
    ******************************************************************************/
    fn_MoveMSPRC150M () {
      let pParams = {
        custCardVO: this.custCardVO
      }
      this.$router.push({ name: 'MSPRC150M', params: pParams }) // 후보자등록화면
    },
    /******************************************************************************
     * @function fn_MoveMSPPI700M
     * @notice   가입보장설계 화면 전환
    ******************************************************************************/
    fn_MoveMSPPI700M () { 
      let pParams = {
        custNm: this.custCardVO.custNm, // 고객명
        chnlCustId: this.custCardVO.chnlCustId, // 채널고객ID
      }
      this.$router.push( { name: 'MSPPI700M', params: pParams } )
    },
    /******************************************************************************
    * @function fn_Back
    * @notice   헤더 영역 Back Button 파라미터 넘기기
    ******************************************************************************/
    fn_Back () {
      this.fn_MovePage()
    }
  }
}
</script>
